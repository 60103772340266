import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Opportunities_detail_card.css";
import photo1 from "../../../assets/images/opportunity/oppcardpic.jpg";
import handicap from "../../../assets/images/flags/wheelchair.png";
import food from "../../../assets/images/flags/food.png";
import transport from "../../../assets/images/flags/transport.png";
import calltoaction from "../../../assets/images/flags/calltoaction.png";
import { MdOutlineVerified } from "react-icons/md";
import image77 from "../../../assets/images/about/carouselfirstimg.jpeg";

import { IoMdHeartEmpty } from "react-icons/io";
import { BsShare } from "react-icons/bs";
import { BsSun } from "react-icons/bs";
import { AiOutlinePieChart } from "react-icons/ai";
import { TfiLocationPin } from "react-icons/tfi";
import calendaropp from "../../../assets/images/calendaropp.png";
import { SlClock } from "react-icons/sl";
import { AiOutlineUser } from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import { SlArrowDown } from "react-icons/sl";
import { useTranslation } from "react-i18next";
import { FaHeart } from "react-icons/fa";
import i18n from "../../../i18n";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getOpportunitiesById,
  getdisabilitiesbyid,
  getimpactsbyorgid,
} from "../../../Redux/opportunities/opportunityActions";
import { getOrganizationsById } from "../../../Redux/organizations/OrganizationsActions";
import { checkIfVolunteerReported } from "../../../Redux/requests/requestsActions";
import {
  addtoFavorites,
  removeFromFavorites,
  getFavoritesbytoken,
  getAllFavorites,
} from "../../../Redux/favorites/FavoritesActions";

//greensection icons
import calendargreen from "../../../assets/images/opp_detail_icons/appointment.png";
import mobile from "../../../assets/images/opp_detail_icons/mobile-phone.png";
import worldwide from "../../../assets/images/opp_detail_icons/worldwide.png";
import heartbeat from "../../../assets/images/opp_detail_icons/heartbeat.png";
import suitcase from "../../../assets/images/opp_detail_icons/suitcase (1).png";
import heart2 from "../../../assets/images/opp_detail_icons/heart (3).png";
import confeti from "../../../assets/images/opp_detail_icons/confetti.png";
import achievement from "../../../assets/images/opp_detail_icons/achievement.png";
import { useParams } from "react-router-dom";
import PopUp from "../../../components/PopUp/PopUp";
import Apply_Step1 from "../Applies/Apply_Step1/Apply_Step1";
import axios from "axios";
import Loading from "../../../components/Loading/Loading";
import { TbHeartPlus } from "react-icons/tb";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

function Opportunities_detail_card() {
  const applyOpenn = useLocation().state;
  const [sectors, setSectors] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const [currentDropdown, setCurrentDropdown] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [index, setindex] = useState(false);

  const [currentDropdown1, setCurrentDropdown1] = useState(false);
  const [rotate1, setRotate1] = useState(false);
  const [index1, setindex1] = useState(false);

  const [currentDropdown2, setCurrentDropdown2] = useState(false);
  const [rotate2, setRotate2] = useState(false);
  const [index2, setindex2] = useState(false);

  const [currentDropdown3, setCurrentDropdown3] = useState(false);
  const [rotate3, setRotate3] = useState(false);
  const [index3, setindex3] = useState(false);

  const [currentDropdown4, setCurrentDropdown4] = useState(false);
  const [rotate4, setRotate4] = useState(false);
  const [index4, setindex4] = useState(false);


  const [currentDropdown5, setCurrentDropdown5] = useState(false);
  const [rotate5, setRotate5] = useState(false);
  const [index5, setindex5] = useState(false);

  const {
    oppDetails,
    gender,
    age,
    occupations,
    disablities,
    impacts,
    loading,
    expertises,
    skills,
  } = useSelector((state) => state.opportunitiesReducer);
  const { organizations } = useSelector((state) => state.organizationReducer);
  const { reportedRequests } = useSelector((state) => state.requestReducer);

  const [points, setpoints] = useState([]);
  const [location, setLocation] = useState();
  const [deadline, setDeadline] = useState();
  const [spotsleft, setSpotsleft] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [district, setDistrict] = useState();
  const [country, setCountry] = useState();
  const [governate, setGovernate] = useState();
  const [Saved, setSaved] = useState();
  const [urlshared, seturlshared] = useState();
  const [open, setOpen] = useState(false);
  const [openApply, setOpenApply] = useState(false);
  const navigate = useNavigate();
  const [matchScore, setmatchScore] = useState(false);
  const [noSpots, setNoSpots] = useState(false);
  const { allFav, favoritesAdd } = useSelector(
    (state) => state.favoritesReducer
  );

  const [message, setMessage] = useState("");
  const [cantApp, setCantApp] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    dispatch(getOpportunitiesById(id));
    dispatch(checkIfVolunteerReported());
  }, []);

  useEffect(() => {
    if (applyOpenn == 3) {
      applyOpp();
    }
  }, [applyOpenn]);
  useEffect(() => {
    if (oppDetails?.organization_id) {
      dispatch(getOrganizationsById(oppDetails?.organization_id));
      dispatch(getimpactsbyorgid(oppDetails?.id));
      if (localStorage.getItem("role" == 3)) dispatch(getAllFavorites());

      getSect();
    }

    setSpotsleft(oppDetails?.nb_of_spots - oppDetails?.nb_of_volunteers);

    setLocation(
      oppDetails?.district?.name +
      "," +
      oppDetails?.district?.governate?.name +
      "," +
      oppDetails?.district?.governate?.country?.name +
      "."
    );
    setDistrict(oppDetails?.district?.name);
    setGovernate(oppDetails?.district?.governate?.name);
    setCountry(oppDetails?.district?.governate?.country?.name);

    const date = new Date(oppDetails?.deadline_date);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    setDeadline(`${day}/${month}/${year}`);

    const startdate = new Date(oppDetails?.start_date);
    const startday = startdate.getDate();
    const startmonth = startdate.getMonth() + 1;
    const startyear = startdate.getFullYear();
    setStart(`${startday}/${startmonth}/${startyear}`);

    const enddate = new Date(oppDetails?.end_date);
    const endday = enddate.getDate();
    const endmonth = enddate.getMonth() + 1;
    const endyear = enddate.getFullYear();
    setEnd(`${endday}/${endmonth}/${endyear}`);
  }, [oppDetails]);

  const save = () => {
    if (Saved) {
      dispatch(removeFromFavorites(oppDetails.id));
      setSaved(false);
    } else {
      dispatch(addtoFavorites(oppDetails.id));
      setSaved(true);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("role" == 3))
      if (allFav?.length > 0) {
        if (allFav.find((s) => s.opportunity_id == oppDetails.id)) {
          setSaved(true);
        } else {
          setSaved(false);
        }
      }
  }, [allFav]);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const urlgenerator = () => {
    const url = window.location.href;
    seturlshared(url);
    setOpen(true);
    return url;
  };
  useEffect(() => {
    if (oppDetails.supports_disability == true) {
      dispatch(getdisabilitiesbyid(oppDetails.id));
    }
  }, [oppDetails]);

  const getSect = async () => {
    if (oppDetails.organization_id) {
      const resp = await axios.get(
        process.env.REACT_APP_API +
        "/organizationsector/organization/" +
        oppDetails?.organization_id
      );
      setSectors(resp.data.data);
    }
  };

  const applyOpp = async () => {
    if (spotsleft != 0)
      if (localStorage.getItem("token")) {
        if (localStorage.getItem("role") == 3) {
          if (reportedRequests?.reported) {
            NotificationManager.warning(
              t("CompleteProf.user_reported") +
              reportedRequests?.organization_name,
              "",
              3000
            );
          } else {
            if (localStorage.getItem("step") == 10) {
              const resp = await axios.get(
                process.env.REACT_APP_API +
                "/requests/matchingScoreCriteria/" +
                id
              );
              if (resp.data.score >= 80) setOpenApply(true);
              else {
                setmatchScore(true);
                let messageString = "";
                if (resp.data.warning.includes("Age")) messageString = "Age";
                if (resp.data.warning.includes("Expertise")) {
                  if (messageString != "") {
                    messageString = messageString + ", Expertise";
                  } else {
                    messageString = "Expertise";
                  }
                }
                setMessage(messageString);
              }
            } else {
              setOpenComplete(true);
            }
          }
        } else {
          setCantApp(true);
        }
      } else {
        setOpenLogin(true);
      }
    else setNoSpots(true);
  };

  return (
    <div>
      <NotificationContainer />
      {openApply && (
        <Apply_Step1
          setOpen={setOpenApply}
          open={openApply}
          opportunityId={id}
        />
      )}
      {openComplete && (
        <PopUp
          setOpen={setOpenComplete}
          body={
            <div className="popup_title">
              Please complete your profile so you can apply to opportunities{" "}
              <button
                className="opp_card_apply_btn"
                onClick={() => navigate("/profile-info")}
              >
                Complete Profile
              </button>
            </div>
          }
        />
      )}
      {openLogin && (
        <PopUp
          setOpen={setOpenLogin}
          body={
            <div className="popup_title">
              Please login so you can apply to opportunities{" "}
              <button
                className="opp_card_apply_btn"
                onClick={() => navigate("/auth/login")}
              >
                Login
              </button>
            </div>
          }
        />
      )}

      {matchScore && (
        <PopUp
          setOpen={setmatchScore}
          body={
            <div className="popup_title">
              <span className="pop_up_sub">
                {message} {message != "" ? "do not match! " : ""}Try applying
                for other volunteer opportunities that may suit you best!
              </span>
            </div>
          }
        />
      )}
      {noSpots && (
        <PopUp
          setOpen={setNoSpots}
          body={
            <div className="popup_title">
              We are sorry you can't apply to this opportunity.
              <span className="pop_up_sub">There are no spots left</span>
              <button
                className="opp_card_apply_btn"
                onClick={() => {
                  oppDetails.is_call_to_action
                    ? navigate("/communityAction")
                    : navigate("/opportunities/1");
                }}
              >
                Explore Others
              </button>
            </div>
          }
        />
      )}

      <div
        className="opp_goback_div"
        onClick={() => {
          oppDetails.is_call_to_action
            ? navigate("/communityAction")
            : navigate("/opportunities/1");
        }}
      >
        <SlArrowDown className="opp_goback_icon" />{" "}
        <div>{t("oppdetails.oppdetails_1")}</div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="Main_container">
          <div className="Main_opp_card_div">
            <div className="opp_header_card">
              <div className="img_container">
                <img
                  className="opp_card_img"
                  src={
                    process.env.REACT_APP_API +
                    "/Organization/" +
                    oppDetails?.organization?.logo
                  }
                  alt="opp_card_img"
                />
              </div>
              <div className="opp_card_text_container">
                <div className="opp_card_header_subtitle">
                  {t("oppdetails.oppdetails_2")} &nbsp;
                  {oppDetails?.respond_time ? oppDetails?.respond_time : "24 Hrs"} 
                </div>
                <div className="opp_card_header_title">{oppDetails?.title}</div>
                <div className="opp_card_header_flags_cont">
                  {oppDetails?.wheelchair_accessible === true && (
                    <img className="opp_card_flag_comp_1" src={handicap} />
                  )}
                  {oppDetails?.food === "Provided" && (
                    <img className="opp_card_flag_comp_1" src={food} />
                  )}
                  {oppDetails?.transporation === "Provided" && (
                    <img className="opp_card_flag_comp_1" src={transport} />
                  )}

                  {oppDetails?.is_call_to_action && (
                    <img className="opp_card_flag_comp_1" src={calltoaction} />
                  )}
                </div>
              </div>
            </div>
            <div className="opp_card_info_conts">
              <div className="opp_card_save_btn_cont" onClick={save}>
                {Saved ? (
                  <>
                    <FaHeart size={25} className="opp_card_logo" />
                    <div
                      className={
                        lang === "en"
                          ? "opp_card_save_text"
                          : "opp_card_save_text_ar"
                      }
                    >
                      {t("oppdetails.oppdetails_3")}
                    </div>
                  </>
                ) : (
                  <>
                    <IoMdHeartEmpty size={30} className="opp_card_logo" />
                    <div
                      className={
                        lang === "en"
                          ? "opp_card_save_text"
                          : "opp_card_save_text_ar"
                      }
                    >
                      {t("oppdetails.oppdetails_3")}
                    </div>
                  </>
                )}
              </div>
              {/* <div className="divider" /> */}
              <div
                className={
                  lang === "en"
                    ? "opp_card_share_btn_cont"
                    : "opp_card_share_btn_cont_ar"
                }
              >
                <div
                  className="opp_card_save_btn_cont_2"
                  onClick={urlgenerator}
                >
                  <BsShare size={25} className="opp_card_logo" />
                  <div
                    className={
                      lang === "en"
                        ? "opp_card_save_text"
                        : "opp_card_save_text_ar"
                    }
                  >
                    {t("oppdetails.oppdetails_4")}
                  </div>
                </div>
              </div>
              {open && (
                <PopUp
                  setOpen={setOpen}
                  body={
                    <div className="popup_body">
                      <div className="div_share">
                        <input
                          className="home_popup_body_link"
                          value={urlshared}
                        ></input>
                        <button
                          className="home_popup_btn"
                          onClick={() =>
                            navigator.clipboard.writeText(urlshared) &&
                            setOpen(false)
                          }
                        >
                          {" "}
                          {t("oppdetails.invite_volunteer")}
                        </button>
                      </div>
                    </div>
                  }
                />
              )}
              {/* <div className="divider" /> */}
              <div className="opp_card_apply_btn_cont">
                {!cantApp && applyOpenn != 2 && (
                  <button className="opp_card_apply_btn" onClick={applyOpp}>
                    {t("oppdetails.oppdetails_5")}
                  </button>
                )}
              </div>
            </div>
            <div className="opp_card_line1_cont">
              <div
                className={
                  lang === "en"
                    ? "opp_card_line1_text"
                    : "opp_card_line1_text_ar"
                }
              >
                <div className="opp_card_containers">
                  <BsSun size={30} className="opp_card_logo greeny" />
                  <div
                    className={
                      lang === "en"
                        ? "opp_card_line1_text_subtitle"
                        : "opp_card_line1_text_subtitle_ar"
                    }
                  >
                    <div className="opp_card_line1_text_title">
                      {t("oppdetails.oppdetails_6")}
                    </div>
                    {impacts.map((impact) => (
                      <div>{impact.impact.title}</div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="divider" /> */}
              <div
                className={
                  lang === "en"
                    ? "opp_card_line1_text"
                    : "opp_card_line1_text_ar"
                }
              >
                <div
                  className={
                    lang === "en"
                      ? "opp_card_containers_2"
                      : "opp_card_containers_2_ar"
                  }
                >
                  {/* <AiOutlinePieChart size={25} className="opp_card_logo" /> */}
                  <img src={image77} className="opp_card_imng8" />
                  <div
                    className={
                      lang === "en"
                        ? "opp_card_line1_text_subtitle"
                        : "opp_card_line1_text_subtitle_ar"
                    }
                  >
                    <div className="opp_card_line1_text_title">
                      {t("oppdetails.oppdetails_7")}
                    </div>
                    {sectors?.map((sec) => {
                      return (
                        <div>
                          {lang === "en"
                            ? sec?.sector?.name
                            : sec?.sector?.name_ar}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="opp_card_line1_text_5">
                <div
                  className={
                    lang === "en"
                      ? "opp_card_containers_2"
                      : "opp_card_containers_2_ar"
                  }
                >
                  <TbHeartPlus size={30} className="opp_card_logo pinky" />
                  <div
                    className={
                      lang === "en"
                        ? "opp_card_line1_text_subtitle"
                        : "opp_card_line1_text_subtitle_ar"
                    }
                  >
                    <div className="opp_card_line1_text_title">
                      {t("oppdetails.oppdetails_22")}
                    </div>
                    {oppDetails.supports_disability === false ? (
                      <div>No</div>
                    ) : (
                      <>
                        {disablities.map((disablity) => (
                          <div>
                            {lang === "en"
                              ? disablity?.disability?.text
                              : disablity?.disability?.text_ar}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="opp_card_line1_text_3"></div> */}
            </div>
            <div className="opp_card_line1_cont">
              <div
                className={
                  lang === "en"
                    ? "opp_card_line1_text"
                    : "opp_card_line1_text_ar"
                }
              >
                <div className="opp_card_containers">
                  <TfiLocationPin size={30} className="opp_card_logo greeny" />
                  <div
                    className={
                      lang === "en"
                        ? "opp_card_line1_text_subtitle"
                        : "opp_card_line1_text_subtitle_ar"
                    }
                  >
                    <div className="opp_card_line1_text_title">
                      {t("oppdetails.oppdetails_8")}
                    </div>
                    {district}, {governate}, {country}
                  </div>
                </div>
              </div>
              {/* <div className="divider" /> */}
              <div
                className={
                  lang === "en"
                    ? "opp_card_line1_text"
                    : "opp_card_line1_text_ar"
                }
              >
                <div
                  className={
                    lang === "en"
                      ? "opp_card_containers_2"
                      : "opp_card_containers_2_ar"
                  }
                >
                  <SlClock size={30} className="opp_card_logo pinky" />
                  <div
                    className={
                      lang === "en"
                        ? "opp_card_line1_text_subtitle"
                        : "opp_card_line1_text_subtitle_ar"
                    }
                  >
                    <div className="opp_card_line1_text_title">
                      {t("oppdetails.oppdetails_11")}
                    </div>
                    {deadline}
                  </div>
                </div>
              </div>
              <div className="opp_card_line1_text_2">
                <div
                  className={
                    lang === "en"
                      ? "opp_card_containers_2"
                      : "opp_card_containers_2_ar"
                  }
                >
                  <AiOutlineUser size={35} className="opp_card_logo greeny" />

                  <div
                    className={
                      lang === "en"
                        ? "opp_card_line1_text_subtitle"
                        : "opp_card_line1_text_subtitle_ar"
                    }
                  >
                    <div className="opp_card_line1_text_title">
                      {t("oppdetails.oppdetails_12")}
                    </div>
                    {spotsleft}
                  </div>
                </div>
              </div>
            </div>
            <div className="opp_card_line1_cont">
              <div
                className={
                  lang === "en"
                    ? "opp_card_line1_text"
                    : "opp_card_line1_text_ar"
                }
              >
                <div className="opp_card_containers">
                  <img src={calendaropp} className="opp_card_logo_2 " />
                  <div
                    className={
                      lang === "en"
                        ? "opp_card_line1_text_subtitle"
                        : "opp_card_line1_text_subtitle_ar"
                    }
                  >
                    <div className="opp_card_line1_text_title">
                      {t("oppdetails.oppdetails_9")}
                    </div>
                    {start}
                  </div>
                </div>
              </div>
              {/* <div className="divider" /> */}
              <div
                className={
                  lang === "en"
                    ? "opp_card_line1_text"
                    : "opp_card_line1_text_ar"
                }
              >
                <div
                  className={
                    lang === "en"
                      ? "opp_card_containers_2"
                      : "opp_card_containers_2_ar"
                  }
                >
                  <img src={calendaropp} className="opp_card_logo_2" />

                  <div
                    className={
                      lang === "en"
                        ? "opp_card_line1_text_subtitle"
                        : "opp_card_line1_text_subtitle_ar"
                    }
                  >
                    <div className="opp_card_line1_text_title">
                      {t("oppdetails.oppdetails_10")}
                    </div>
                    {end}
                  </div>
                </div>
              </div>
              <div className="opp_card_line1_text_2">
                <div
                  className={
                    lang === "en"
                      ? "opp_card_containers_2"
                      : "opp_card_containers_2_ar"
                  }
                >
                  <BsCircle size={35} className="opp_card_logo pinky" />
                  <div
                    className={
                      lang === "en"
                        ? "opp_card_line1_text_subtitle"
                        : "opp_card_line1_text_subtitle_ar"
                    }
                  >
                    <div className="opp_card_line1_text_title">
                      {t("oppdetails.oppdetails_13")}
                    </div>
                    {oppDetails.type === "Time-based" ? "10" : "20"}
                    pts/hour
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="green_opp_section">
            <div className="green_contents_container">
              <div className="green_opp_section_icon">
                <img src={calendargreen} className="green_img_logo" />
                <div
                  className={
                    lang === "en"
                      ? "green_opp_section_text"
                      : "green_opp_section_text_ar"
                  }
                >
                  <div className="green_opp_section_text_title">
                    {t("oppdetails.oppdetails_14")}
                  </div>
                  <div className="green_opp_section_text_subtitle">
                    {oppDetails?.hours}
                  </div>
                </div>
              </div>
            </div>

            <div className="green_contents_container">
              <div className="green_opp_section_icon">
                <img src={worldwide} className="green_img_logo" />
                <div
                  className={
                    lang === "en"
                      ? "green_opp_section_text"
                      : "green_opp_section_text_ar"
                  }
                >
                  <div className="green_opp_section_text_title">
                    {t("oppdetails.oppdetails_16")}
                  </div>
                  <div className="green_opp_section_text_subtitle">
                    {oppDetails?.transporation}
                  </div>
                </div>
              </div>
            </div>
            <div className="green_contents_container">
              <div className="green_opp_section_icon">
                <img src={heartbeat} className="green_img_logo" />
                <div
                  className={
                    lang === "en"
                      ? "green_opp_section_text"
                      : "green_opp_section_text_ar"
                  }
                >
                  <div className="green_opp_section_text_title">
                    {t("oppdetails.oppdetails_18")}
                  </div>
                  <div className="green_opp_section_text_subtitle">
                    {oppDetails?.frequency}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="green_contents_container">
              <div className="green_opp_section_icon">
                <img src={suitcase} className="green_img_logo" />
                <div
                  className={
                    lang === "en"
                      ? "green_opp_section_text"
                      : "green_opp_section_text_ar"
                  }
                >
                  <div className="green_opp_section_text_title">
                    {t("oppdetails.oppdetails_20")}
                  </div>
                    <div className="green_opp_section_text_subtitle">
                      {console.log(oppDetails)}
                      {occupations.map((g, i) => {
                        if (i < occupations.length - 1) return g.occupation + "/";
                        else return g.occupation;
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="green_contents_container">
              <div className="green_opp_section_icon">
                <img src={mobile} className="green_img_logo_2" />
                <div
                  className={
                    lang === "en"
                      ? "green_opp_section_text"
                      : "green_opp_section_text_ar"
                  }
                >
                  <div className="green_opp_section_text_title">
                    {t("oppdetails.oppdetails_15")}
                  </div>
                  <div className="green_opp_section_text_subtitle">
                    {oppDetails.online === "Yes" ? "Online" : "Onsite"}
                  </div>
                </div>
              </div>
            </div>
            <div className="green_contents_container">
              <div className="green_opp_section_icon">
                <img src={heart2} className="green_img_logo" />
                <div
                  className={
                    lang === "en"
                      ? "green_opp_section_text"
                      : "green_opp_section_text_ar"
                  }
                >
                  <div className="green_opp_section_text_title">
                    {t("oppdetails.oppdetails_17")}
                  </div>
                  <div className="green_opp_section_text_subtitle">
                    {gender.map((g, i) => {
                      if (i < gender.length - 1) return g.gender + "/";
                      else return g.gender;
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="green_contents_container">
              <div className="green_opp_section_icon">
                <img src={confeti} className="green_img_logo" />
                <div
                  className={
                    lang === "en"
                      ? "green_opp_section_text"
                      : "green_opp_section_text_ar"
                  }
                >
                  <div className="green_opp_section_text_title">
                    {t("oppdetails.oppdetails_19")}
                  </div>
                  <div className="green_opp_section_text_subtitle">
                    {age[0]?.age}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="green_contents_container">
              <div className="green_opp_section_icon">
                <img src={achievement} className="green_img_logo" />
                <div
                  className={
                    lang === "en"
                      ? "green_opp_section_text"
                      : "green_opp_section_text_ar"
                  }
                >
                  <div className="green_opp_section_text_title">
                    {t("oppdetails.oppdetails_21")}
                  </div>
                  <div className="green_opp_section_text_subtitle">
                    {oppDetails?.languages}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opp_card_bottom_div">
            <div className="opp_card_bottom_div_container">
              <div className="opp_card_bottom_title">
                {organizations.name}
                <div className="opp_card_bottom_verified">
                  {/* {organizations.verified === "false" && (
                    <MdOutlineVerified
                      className="opp_card_bottom_verified_icon"
                      size={25}
                    />
                  )} */}
                  {organizations.verified === true && (
                    <MdOutlineVerified
                      className="opp_card_bottom_verified_icon"
                      size={30}
                    />
                  )}
                </div>
              </div>

              <div className="opp_card_bottom_subtitle">
                {organizations.type}
              </div>
            </div>

            {organizations.about && (
              <div className="mapping_box_bottom">
                <div className="opp_card_bottom_div_line">
                  <p className="opp_deatil_ngo_desc">
                    {" "}
                    {t("oppdetails.organisation_description")}
                  </p>
                  <span className="arrow">
                    <SlArrowDown
                      className={
                        index2 == 0 && !rotate2
                          ? "arrow-icon"
                          : "arrow-icon-rotate"
                      }
                      onClick={() => {
                        setRotate2(!rotate2);
                        setindex2(0);
                        if (currentDropdown2 == true)
                          setCurrentDropdown2(false);
                        else setCurrentDropdown2(true) && setRotate2(rotate2);
                      }}
                    />
                  </span>
                  {/* <div className="opp_card_bottom_div_line_container" /> */}
                </div>
                {currentDropdown2 && (
                  <div className="opp_card_bottom_description_container">
                    {organizations.about}
                  </div>
                )}
              </div>
            )}

            {oppDetails?.experience_required && (
              <div className="mapping_box_bottom">
                <div className="opp_card_bottom_div_line">
                  <p className="opp_deatil_ngo_desc">
                    {" "}
                    {t("oppdetails.role_of_vol")}
                  </p>
                  <span className="arrow">
                    <SlArrowDown
                      className={
                        index == 0 && !rotate
                          ? "arrow-icon"
                          : "arrow-icon-rotate"
                      }
                      onClick={() => {
                        setRotate(!rotate);
                        setindex(0);
                        if (currentDropdown == true) setCurrentDropdown(false);
                        else setCurrentDropdown(true) && setRotate(rotate);
                      }}
                    />
                  </span>
                  {/* <div className="opp_card_bottom_div_line_container" /> */}
                </div>
                {currentDropdown && (
                  <div className="opp_card_bottom_description_container">
                    {oppDetails?.experience_required}
                  </div>
                )}
              </div>
            )}
            {oppDetails?.final_comments && (
              <div className="mapping_box_bottom">
                <div className="opp_card_bottom_div_line">
                  <p className="opp_deatil_ngo_desc">
                    {" "}
                    {t("oppdetails.ngo_notes")}
                  </p>
                  <span className="arrow">
                    <SlArrowDown
                      className={
                        index1 == 0 && !rotate1
                          ? "arrow-icon"
                          : "arrow-icon-rotate"
                      }
                      onClick={() => {
                        setRotate1(!rotate1);
                        setindex1(0);
                        if (currentDropdown1 == true)
                          setCurrentDropdown1(false);
                        else setCurrentDropdown1(true) && setRotate1(rotate1);
                      }}
                    />
                  </span>
                  {/* <div className="opp_card_bottom_div_line_container" /> */}
                </div>
                {currentDropdown1 && (
                  <div className="opp_card_bottom_description_container">
                    {oppDetails?.final_comments}
                  </div>
                )}
              </div>
            )}



            {oppDetails?.ideal_candidate_comments && (
              <div className="mapping_box_bottom">
                <div className="opp_card_bottom_div_line">
                  <p className="opp_deatil_ngo_desc">
                    {" "}
                    {t("oppdetails.ideal_condidate")}
                  </p>
                  <span className="arrow">
                    <SlArrowDown
                      className={
                        index5 == 0 && !rotate5
                          ? "arrow-icon"
                          : "arrow-icon-rotate"
                      }
                      onClick={() => {
                        setRotate5(!rotate5);
                        setindex5(0);
                        if (currentDropdown5 == true)
                          setCurrentDropdown5(false);
                        else setCurrentDropdown5(true) && setRotate5(rotate5);
                      }}
                    />
                  </span>
                  {/* <div className="opp_card_bottom_div_line_container" /> */}
                </div>
                {currentDropdown5 && (
                  <div className="opp_card_bottom_description_container">
                    {oppDetails?.ideal_candidate_comments}
                  </div>
                )}
              </div>
            )}




            {expertises && (
              <div className="mapping_box_bottom">
                <div className="opp_card_bottom_div_line">
                  <p className="opp_deatil_ngo_desc">
                    {" "}
                    {t("CompleteProf.expertise")}
                  </p>
                  <span className="arrow">
                    <SlArrowDown
                      className={
                        index3 == 0 && !rotate3
                          ? "arrow-icon"
                          : "arrow-icon-rotate"
                      }
                      onClick={() => {
                        setRotate3(!rotate3);
                        setindex3(0);
                        if (currentDropdown3 == true)
                          setCurrentDropdown3(false);
                        else setCurrentDropdown3(true) && setRotate3(rotate3);
                      }}
                    />
                  </span>
                  {/* <div className="opp_card_bottom_div_line_container" /> */}
                </div>
                {currentDropdown3 && (
                  <div className="opp_card_bottom_description_container">
                    {expertises.map((expertise) => (
                      <div>
                        {lang === "en" ? expertise.name : expertise.name_ar}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {skills && (
              <div className="mapping_box_bottom">
                <div className="opp_card_bottom_div_line">
                  <p className="opp_deatil_ngo_desc">
                    {" "}
                    {t("CompleteProf.skills")}
                  </p>
                  <span className="arrow">
                    <SlArrowDown
                      className={
                        index4 == 0 && !rotate4
                          ? "arrow-icon"
                          : "arrow-icon-rotate"
                      }
                      onClick={() => {
                        setRotate4(!rotate4);
                        setindex4(0);
                        if (currentDropdown4 == true)
                          setCurrentDropdown4(false);
                        else setCurrentDropdown4(true) && setRotate4(rotate4);
                      }}
                    />
                  </span>
                  {/* <div className="opp_card_bottom_div_line_container" /> */}
                </div>
                {currentDropdown4 && (
                  <div className="opp_card_bottom_description_container">
                    {skills.map((skill) => (
                      <div>{lang === "en" ? skill.name : skill.name_ar}</div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Opportunities_detail_card;
