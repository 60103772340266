import React, { useEffect, useState } from "react";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import "./Apply_Step4.css";
import apply_img_step4 from "../../../../assets/images/opportunity/apply/apply_step4.png";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import Apply_Step5 from "../Apply_Step5/Apply_Step5";
import Apply_Step6 from "../Apply_Step6/Apply_Step6";
import Apply_Step3 from "../Apply_Step3/Apply_Step3";
import Select from "react-select";
import { getuserinfo } from "../../../../Redux/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";

function Apply_Step4({
  setOpen,
  open,
  data,
  setFooterWidth,
  opportunityId,
  openOutOfAreaa,
}) {
  const { t } = useTranslation();
  const MAX_GROUP_MEMBER = 5;
  const [lang, setLang] = useState(i18n.language);
  const [openApplyPopUpStep, setOpenApplyPopUpStep] = useState(false);
  const size = useWindowSize();
  const [oppData, setOppData] = useState("");
  const [openOutOfArea, setOpenOutOfArea] = useState(false);
  const [richedMaxGroupNumb, setRichedMaxGroupNumb] = useState(false);
  const token = localStorage.getItem("token");
  const [previous, setPrevious] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [fields, setFields] = useState([
    {
      full_name: "",
      occupation: "",
    },
  ]);
  const allOcupations = [
    {
      value: "Unoccupied",
      label: lang == "en" ? "Unoccupied" : "شاغر",
    },
    {
      value: "School Student",
      label: lang == "en" ? "School Student" : "طالب مدرسة",
    },

    {
      value: "University Student",
      label: lang == "en" ? "University Student" : "طالب جامعي",
    },
    {
      value: "Employee",
      label: lang == "en" ? "Employee" : "موظف",
    },
    {
      value: "Self Employed",
      label: lang == "en" ? "Self Employed" : "موطف لحسابي الخاص",
    },
    {
      value: "Shifting careers",
      label: lang == "en" ? "Shifting careers" : "تغيير المهن",
    },
    {
      value: "Employee with job security (contract & benefits)",
      label:
        lang == "en"
          ? "Employee with job security (contract & benefits)"
          : "موظف يتمتع بالأمان الوظيفي (عقد ومزايا)",
    },
    {
      value: "Employee without contract & benefits",
      label:
        lang == "en"
          ? "Employee without contract & benefits"
          : "موظف بدون عقد ومزايا",
    },
  ];

  const addInput = () => {
    if (fields.length < MAX_GROUP_MEMBER) {
      setFields((fields) => [
        ...fields,
        {
          full_name: "",
          occupation: "",
        },
      ]);
    }
    if (fields.length == MAX_GROUP_MEMBER - 1) {
      setRichedMaxGroupNumb(true);
    }
  };
  const { userinfo } = useSelector((store) => store.userReducer);
  const { oppDetails } = useSelector((state) => state.opportunitiesReducer);
  const dispatch = useDispatch();
  const [opprtunityDetails, setOpprtunityDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    setPrevious(false);
    setDisabledButtons(true);
    dispatch(getOpportunitiesById(data.opportunityId));
    dispatch(getuserinfo(token));
    setOppData({ ...data });
    setFooterWidth("50%");
  }, []);

  useEffect(() => {
    if (
      oppDetails != {} &&
      userinfo != {} &&
      oppDetails.length != 0 &&
      userinfo.length != 0
    ) {
      setOpprtunityDetails(oppDetails);
      setUserDetails(userinfo);
      setDisabledButtons(false);
    }
  }, [oppDetails, userinfo]);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      userDetails?.district_id != opprtunityDetails?.district_id &&
      opprtunityDetails?.district_id != undefined &&
      userDetails?.district_id != undefined
    ) {
      setOpenOutOfArea(true);
    }
    setOppData({ ...oppData, group_members: fields });
    setOpenApplyPopUpStep(true);
  };

  const updateInput = (e, index, type) => {
    const newState = fields.map((obj, i) => {
      if (i === index) {
        if (type == "full_name") {
          return {
            full_name: e.target.value,
            occupation: fields[i]["occupation"],
          };
        } else {
          return {
            full_name: fields[i]["full_name"],
            occupation: e,
          };
        }
      }
      return obj;
    });

    setFields(newState);
  };

  const removeInput = (index) => {
    const newfields = [...fields];
    newfields.splice(index, 1);
    setFields(newfields);
    if (newfields.length < MAX_GROUP_MEMBER) {
      setRichedMaxGroupNumb(false);
    }
  };

  return (
    <>
      {previous && (
        <Apply_Step3
          setOpen={setOpen}
          open={open}
          data={data}
          openOutOfArea={openOutOfAreaa}
          opportunityId={opportunityId}
          setFooterWidth={setFooterWidth}
        />
      )}

      {!previous && !openApplyPopUpStep ? (
        <form className="apply_step4" onSubmit={(e) => onSubmit(e)}>
          <div className="apply_step4_sections">
            <div className="apply_section1">
              <img className="apply_img" src={apply_img_step4} />
              <div className="group_name">{oppData.fullName}</div>
              <div className="out_of_div">
                {t("opportunity.apply_popup.spots_left")}{" "}
                <span className="green_color">
                  {fields.length +
                    " " +
                    t("opportunity.apply_popup.out_of") +
                    " " +
                    MAX_GROUP_MEMBER}
                </span>
              </div>
              <div
                className={!richedMaxGroupNumb ? "add_group_div" : "block-div"}
                onClick={addInput}
              >
                <div className="green-circle">
                  {" "}
                  <AiOutlinePlus
                    color="white
 "
                  />
                </div>
                <div className="green_color">
                  {" "}
                  {t("opportunity.apply_popup.add_group_member")}
                </div>
              </div>
              <div className="add_group_inputs_div">
                {fields.map((oject, index) => {
                  return (
                    <div className="div_container">
                      <div id={index} className="add_group_inputs">
                        <input
                          onChange={(e) => updateInput(e, index, "full_name")}
                          value={oject["full_name"]}
                          className="fullName_input"
                          type="text"
                          style={{ cursor: "pointer" }}
                          placeholder={t("opportunity.apply_popup.fullName")}
                          required
                        />

                        <Select
                          className="occupation_input"
                          name="occupation"
                          onChange={(e) => updateInput(e, index, "occupation")}
                          placeholder={t("opportunity.apply_popup.occupation")}
                          isSearchable={false}
                          options={allOcupations}
                          value={oject["occupation"]}
                          required
                        />
                      </div>
                      <AiOutlineClose
                        className="complete_step2_close"
                        size="1.2rem"
                        onClick={() => removeInput(index)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="dotter_spacer" />
          <div class="complete_step_footer_button">
            <div className="div_buttons div_buttons_left">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPrevious(true);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step2_apply_later_button gray-btn"
                    : "apply_primary_button app_step2_apply_later_button_resp gray-btn"
                }
              >
                {t("opportunity.apply_popup.previous")}
              </button>
            </div>
            <div className="div_buttons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step4_apply_later_button"
                    : "apply_primary_button app_step4_apply_later_button_resp"
                }
              >
                {t("opportunity.apply_popup.apply_later")}
              </button>
              <button
                type="submit"
                className={
                  size.width > 768
                    ? "primary_button app_step4_next_button"
                    : "primary_button app_step4_next_button_resp"
                }
                disabled={disabledButtons}
              >
                {size.width > 768 ? (
                  t("opportunity.apply_popup.next")
                ) : lang == "en" ? (
                  <AiOutlineArrowRight size="1.5rem" />
                ) : (
                  <AiOutlineArrowLeft size="1.5rem" />
                )}
              </button>
            </div>
          </div>
        </form>
      ) : !previous && !openOutOfArea ? (
        !previous && (
          <Apply_Step5
            setOpen={setOpen}
            previousFirstPage={false}
            open={open}
            data={oppData}
            opportunityId={opportunityId}
            openOutOfArea={openOutOfAreaa}
            setFooterWidth={setFooterWidth}
          />
        )
      ) : (
        !previous && (
          <Apply_Step6
            setOpen={setOpen}
            open={open}
            data={oppData}
            previousFirstPage={false}
            openOutOfArea={openOutOfAreaa}
            opportunityId={opportunityId}
            setFooterWidth={setFooterWidth}
          />
        )
      )}
    </>
  );
}

export default Apply_Step4;
