import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  opportunities: [],
  gender: [],
  disabilities: [],
  expertises: [],
  skills: [],
  age: [],
  occupations: [],
  loading: false,
  checkInOutLoading: false,
  error: "",
  oppDetails: {},
  orgOpp: [],
  oppByStatus: [],
  favOpp: [],
  sectors: [],
  oppId: "",
  recOpp: [],
  disablities: [],
  impacts: [],
  reminder: [],
  checks: [],

  createLoading: false,
  createError: "",
  createSucess: false,

  editLoading: false,
  editError: "",
  editSucess: false,

  volunteerFeedbackQuestion:[],
  volunteerFeedbackError:"",
  volunteerFeedbackLoading: false,

};

const opportunitySlice = createSlice({
  name: "opportunities",
  initialState,
  reducers: {
    fetchOpportunitiesRequest(state) {
      state.loading = true;
      state.opportunities = [];
      state.gender = [];
      state.age = [];
      state.occupations = [];
      state.sectors = [];
      state.error = "";
    },

    fetchOpportunitiesSuccess(state, action) {
      state.loading = false;
      state.opportunities = action.payload;
      state.error = "";
    },

    fetchOpportunitiesFail(state, action) {
      state.loading = false;
      state.opportunities = [];
      state.error = action.payload;
    },

    fetchOppDetailsRequest(state) {
      state.loading = true;
      state.oppDetails = [];
      state.gender = [];
      state.disabilities=[];
      state.expertises=[];
      state.skills=[];
      state.age = [];
      state.occupations = [];
      state.sectors = [];
      state.error = "";
    },

    fetchOppDetailsSuccess(state, action) {
      state.loading = false;
      state.oppDetails = action.payload.data[0];
      state.gender = action.payload.gender;
      state.disabilities=action.payload.disabilities;
      state.expertises=action.payload.expertise;
      state.skills=action.payload.skills;
      state.age = action.payload.age;
      state.occupations = action.payload.occupations;
      state.sectors = action.payload.organization_sectors;
      state.error = "";
    },

    fetchOppDetailsFail(state, action) {
      state.loading = false;
      state.oppDetails = [];
      state.disabilities = [];
      state.expertises=[];
      state.skills=[];
      state.error = action.payload;
    },

    fetchOppByStatusRequest(state) {
      state.loading = true;
      state.oppByStatus = [];
      state.error = "";
    },

    fetchOppByStatusSuccess(state, action) {
      state.loading = false;
      state.oppByStatus = action.payload;

      state.error = "";
    },

    fetchOppByStatusFail(state, action) {
      state.loading = false;
      state.favOpp = [];
      state.error = action.payload;
    },

    fetchFavOppRequest(state) {
      state.loading = true;
      state.favOpp = [];
      state.error = "";
    },

    fetchFavOppSuccess(state, action) {
      state.loading = false;
      state.favOpp = action.payload;

      state.error = "";
    },

    fetchOppByStatusFail(state, action) {
      state.loading = false;
      state.oppByStatus = [];
      state.error = action.payload;
    },

    fetchOrgOppRequest(state) {
      state.loading = true;
      state.orgOpp = [];
      state.error = "";
    },

    fetchOrgOppSuccess(state, action) {
      state.loading = false;
      state.orgOpp = action.payload;
      state.error = "";
    },

    fetchOrgOppFail(state, action) {
      state.loading = false;
      state.orgOpp = [];
      state.error = action.payload;
    },

    fetchRecOppRequest(state) {
      state.loading = true;
      state.recOpp = [];
      state.error = "";
    },

    fetchRecOppSuccess(state, action) {
      state.loading = false;
      state.recOpp = action.payload;
      state.error = "";
    },

    fetchRecOppFail(state, action) {
      state.loading = false;
      state.recOpp = [];
      state.error = action.payload;
    },
    updateOppRequest(state, action) {
      state.editLoading = false;
      state.editError = action.payload;
      state.editSucess = false;
    },

    updateOppSucess(state, action) {
      state.editLoading = false;
      state.editError = "";
      state.editSucess = true;
    },

    updateOppFail(state, action) {
      state.editLoading = false;
      state.editError = action.payload;
      state.editSucess = false;
    },

    volunteerFeedbackRequest(state, action) {
      state.volunteerFeedbackLoading = true;
      state.volunteerFeedbackQuestion = [];
      state.volunteerFeedbackError = "";
    },

    volunteerFeedbackSuccess(state, action) {
      state.volunteerFeedbackLoading = false;
      state.volunteerFeedbackQuestion = action.payload;
      state.volunteerFeedbackError = "";
    },

    volunteerFeedbackFail(state, action) {
      state.volunteerFeedbackLoading = false;
      state.volunteerFeedbackQuestion = [];
      state.volunteerFeedbackError = action.payload;
    },



    updateRequestStatus(state, action) {
      state.checkInOutLoading = true;
      state.error = action.payload;
    },

    updateRequestStatusSuccess(state, action) {
      state.checkInOutLoading = false;
      state.checks = action.payload;
      state.error = "";
    },

    createOppRequest(state, action) {
      state.createError = "";
      state.createLoading = true;
      state.createSucess = false;
      state.oppId = "";
    },

    createOppSuccess(state, action) {
      state.createError = "";
      state.createLoading = false;
      state.createSucess = true;
      state.oppId = action.payload.id;
    },

    createOppFail(state, action) {
      state.createError = action.payload;
      state.createLoading = false;
      state.createSucess = false;
      state.oppId = "";
    },
    fetchdisablitiesRequest(state) {
      state.loading = true;
      state.disablities = [];
      state.error = "";
    },
    fetchdisablitiesSuccess(state, action) {
      state.loading = false;
      state.disablities = action.payload;
      state.error = "";
    },
    fetchdisablitiesFail(state, action) {
      state.loading = false;
      state.disablities = [];
      state.error = action.payload;
    },
    fetchimpactsRequest(state) {
      state.loading = true;
      state.impacts = [];
      state.error = "";
    },
    fetchimpactsSuccess(state, action) {
      state.loading = false;
      state.impacts = action.payload;
      state.error = "";
    },
    fetchimpactsFail(state, action) {
      state.loading = false;
      state.impacts = [];
      state.error = action.payload;
    },
    fetchreminderRequest(state) {
      state.loading = true;
      state.reminder = [];
      state.error = "";
    },
    fetchreminderSuccess(state, action) {
      state.loading = false;
      state.reminder = action.payload;
      state.error = "";
    },
    fetchreminderFail(state, action) {
      state.loading = false;
      state.reminder = [];
      state.error = action.payload;
    },
  },
});

export const opportunityActions = opportunitySlice.actions;
export default opportunitySlice;
