import React, { useEffect, useState } from "react";
import "./OurPartners.css";
import { scroller } from "react-scroll";
import partner_img_one from "../../assets/images/partner/transportation_icon.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import medco from "../../assets/images/partner/medco.png";
import connexion from "../../assets/images/partner/connexion.png";
import acedmic from "../../assets/images/partner/academic.png";
import mepi from "../../assets/images/partner/mepi.png";
import community from "../../assets/images/partner/community.png";
import globalShare from "../../assets/images/partner/globalShare.png";
//redux
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchTrusted_partners } from "../../Redux/Trusted_partners/Trusted_partnersActions";

import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
function Partners() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const dispatch = useDispatch();
  const { trusted_partners } = useSelector(
    (store) => store.Trusted_partnersReducer
  );
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const scrollToSection = (classname) => {
    scroller.scrollTo(classname, {
      duration: 800,
      delay: 0,
      offset: -190,
      smooth: "easeInOutQuart",
    });
  };
  useEffect(() => {
    dispatch(fetchTrusted_partners());
  }, []);

  const transportationpart = trusted_partners.filter(
    (item) => item.section == "Transportation Partners"
  );
  const academicpart = trusted_partners.filter(
    (item) => item.section == "Academic Partners"
  );
  const communitypart = trusted_partners.filter(
    (item) => item.section == "Community Partners"
  );

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="partners">
      <div
        className={
          lang == "ar" ? "Partner_title Partner_title_arabic" : "Partner_title"
        }
      >
        {t("partners.header1")}{" "}
        <span className="partners_green_text">{t("partners.header2")}</span>
      </div>
      <div className="partners_navbar">
        <ul>
          <li onClick={() => scrollToSection("transportation_partner")}>
            <div
              className={lang == "ar" ? "navbar_titles" : "navbar_titles_eng"}
            >
              {t("partners.sub1")}
            </div>
          </li>
          <li onClick={() => scrollToSection("academic_partner")}>
            <div
              className={lang == "ar" ? "navbar_titles" : "navbar_titles_eng"}
            >
              {" "}
              {t("partners.sub2")}
            </div>
          </li>
          <li onClick={() => scrollToSection("community_partner")}>
            <div
              className={lang == "ar" ? "navbar_titles" : "navbar_titles_eng"}
            >
              {" "}
              {t("partners.sub3")}
            </div>
          </li>
        </ul>
      </div>
      <div className="img1">
        <img src={partner_img_one} alt="img1" />
      </div>
      <div className="transportation_partner">
        <div className="transportation_title">
          {lang == "en" ? (
            <>
              <span className="red_text">{t("partners.trans")}</span>{" "}
              {t("partners.partner")}
            </>
          ) : (
            <>
              {" "}
              <span className="red_text">{t("partners.partner")}</span>{" "}
              {t("partners.trans")}{" "}
            </>
          )}
        </div>
        <div className="transp_underline"></div>
        <div className="carousel">
          <Carousel responsive={responsive}>
            {transportationpart.map((item) => {
              return (
                <div>
                  <p style={{ marginTop: "2rem", fontFamily: "var(--bold)" }}>
                    {lang == "en" ? item.name : item.name_ar}
                  </p>
                  <div className="carousel_item">
                    <img
                      src={
                        process.env.REACT_APP_API +
                        "/TrustedPartner/" +
                        item.image
                      }
                      alt={item.image}
                    />
                  </div>
                  <div
                    className={
                      lang == "ar"
                        ? "bottom_div bottom_div_arabic"
                        : "bottom_div"
                    }
                  >
                    <span style={{ fontFamily: "var(--bold)" }}>
                      {lang == "en" ? (
                        <>{item.description_title}</>
                      ) : (
                        <>{item.description_title_ar}</>
                      )}
                    </span>
                    <br />
                    {lang == "en" ? item.description : item.description_ar}
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div className="img2">
        <img src={acedmic} alt="img2" />
      </div>
      <div className="academic_partner">
        <div
          className={
            lang == "ar"
              ? "academic_title academic_title_arabic"
              : "academic_title"
          }
        >
          {lang == "en" ? (
            <>
              <span className="green_text">{t("partners.edu")}</span>{" "}
              {t("partners.partner")}
            </>
          ) : (
            <>
              {" "}
              <span className="green_text">{t("partners.partner")}</span>{" "}
              {t("partners.edu")}{" "}
            </>
          )}
        </div>
        <div className="academic_underline"></div>
        <div className="carousel">
          <Carousel responsive={responsive}>
            {academicpart.map((item) => {
              return (
                <div>
                  <p style={{ marginTop: "2rem", fontFamily: "var(--bold)" }}>
                    {lang == "en" ? item.name : item.name_ar}
                  </p>
                  <div className="carousel_item">
                    <img
                      src={
                        process.env.REACT_APP_API +
                        "/TrustedPartner/" +
                        item.image
                      }
                      alt={item.image}
                    />
                  </div>
                  <div
                    className={
                      lang == "ar"
                        ? "bottom_div bottom_div_arabic"
                        : "bottom_div"
                    }
                  >
                    <span style={{ fontFamily: "var(--bold)" }}>
                      {lang == "en" ? (
                        <>{item.description_title}</>
                      ) : (
                        <>{item.description_title_ar}</>
                      )}
                    </span>
                    <br />
                    {lang == "en" ? item.description : item.description_ar}
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div className="img2">
        <img src={community} alt="img2" />
      </div>
      <div className="community_partner">
        <div
          className={
            lang == "ar"
              ? "community_title community_title_arabic"
              : "community_title"
          }
        >
          {lang == "en" ? (
            <>
              <span className="red_text">{t("partners.comm")}</span>{" "}
              {t("partners.partner")}
            </>
          ) : (
            <>
              {" "}
              <span className="red_text">{t("partners.partner")}</span>{" "}
              {t("partners.comm")}{" "}
            </>
          )}
        </div>
        <div className="community_underline"></div>
        <div className="carousel">
          <Carousel responsive={responsive}>
            {communitypart.map((item) => {
              return (
                <div>
                  <p style={{ marginTop: "2rem", fontFamily: "var(--bold)" }}>
                    {lang == "en" ? item.name : item.name_ar}
                  </p>
                  <div className="carousel_item">
                    <img
                      src={
                        process.env.REACT_APP_API +
                        "/TrustedPartner/" +
                        item.image
                      }
                      alt={item.image}
                    />
                  </div>
                  <div
                    className={
                      lang == "ar"
                        ? "bottom_div bottom_div_arabic"
                        : "bottom_div"
                    }
                  >
                    <span style={{ fontFamily: "var(--bold)" }}>
                      {lang == "en" ? (
                        <>{item.description_title}</>
                      ) : (
                        <>{item.description_title_ar}</>
                      )}
                    </span>
                    <br />
                    {lang == "en" ? item.description : item.description_ar}
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Partners;
