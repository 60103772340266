import React, { useEffect, useState } from "react";
import Apply from "../Apply";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import "./Apply_Step3.css";
import apply_img_step3 from "../../../../assets/images/opportunity/apply/apply_step3.png";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import Apply_Step4 from "../Apply_Step4/Apply_Step4";
import Apply_Step2 from "../Apply_Step2/Apply_Step2";
import Select from "react-select";

function Apply_Step3({
  setOpen,
  open,
  data,
  setFooterWidth,
  opportunityId,
  openOutOfArea,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [oppData, setOppData] = useState("");
  const [occupation, setOccupation] = useState(null);
  const [openApplyPopUpStep3, setOpenApplyPopUpStep3] = useState(false);
  const size = useWindowSize();
  const [previous, setPrevious] = useState(false);
  const allOcupations = [
    {
      value: "Unoccupied",
      label: lang == "en" ? "Unoccupied" : "شاغر",
    },
    {
      value: "School Student",
      label: lang == "en" ? "School Student" : "طالب مدرسة",
    },

    {
      value: "University Student",
      label: lang == "en" ? "University Student" : "طالب جامعي",
    },
    {
      value: "Employee",
      label: lang == "en" ? "Employee" : "موظف",
    },
    {
      value: "Self Employed",
      label: lang == "en" ? "Self Employed" : "موطف لحسابي الخاص",
    },
    {
      value: "Shifting careers",
      label: lang == "en" ? "Shifting careers" : "تغيير المهن",
    },
    {
      value: "Employee with job security (contract & benefits)",
      label:
        lang == "en"
          ? "Employee with job security (contract & benefits)"
          : "موظف يتمتع بالأمان الوظيفي (عقد ومزايا)",
    },
    {
      value: "Employee without contract & benefits",
      label:
        lang == "en"
          ? "Employee without contract & benefits"
          : "موظف بدون عقد ومزايا",
    },
  ];

  useEffect(() => {
    setPrevious(false);
    setOppData({ ...data });
    setFooterWidth("37.5%");
  }, []);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const onSubmit = (e) => {
    e.preventDefault();
    setOppData({ ...oppData, occupation: occupation });
    setOpenApplyPopUpStep3(true);
  };

  return (
    <>
      {previous && (
        <Apply_Step2
          setOpen={setOpen}
          open={open}
          data={data}
          openOutOfArea={openOutOfArea}
          opportunityId={opportunityId}
          setFooterWidth={setFooterWidth}
        />
      )}

      {!openApplyPopUpStep3 && !previous ? (
        <form className="apply_step3" onSubmit={(e) => onSubmit(e)}>
          <img className="apply_img" src={apply_img_step3} />
          {t("opportunity.apply_popup.specify_fullName_occupation")}
          <div className="font-14">
            {t("opportunity.apply_popup.assign_contact_numb")}
          </div>

          <div className="inputs_div">
            <PhoneInput
              onChange={(e) => setOppData({ ...oppData, PhoneNumb: e })}
              className="text_field_getintouch"
              country={"lb"}
              placeholder={t("opportunity.apply_popup.PhoneNumber")}
              required
            />

            <div className="font-14 margin-bottom">
              {t("opportunity.apply_popup.specify_fullName_occupation")}
            </div>

            <input
              className="fullName_input"
              type="text"
              style={{ cursor: "pointer" }}
              placeholder={t("opportunity.apply_popup.fullName")}
              onChange={(e) =>
                setOppData({ ...oppData, fullName: e.target.value })
              }
              required
            />

            <Select
              className="occupation_input"
              name="occupation"
              onChange={setOccupation}
              placeholder={t("opportunity.apply_popup.occupation")}
              isSearchable={false}
              options={allOcupations}
              required
            />
          </div>
          <div className="dotter_spacer" />

          <div class="complete_step_footer_button">
            <div className="div_buttons div_buttons_left">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPrevious(true);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step2_apply_later_button gray-btn"
                    : "apply_primary_button app_step2_apply_later_button_resp gray-btn"
                }
              >
                {t("opportunity.apply_popup.previous")}
              </button>
            </div>

            <div className="div_buttons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step3_apply_later_button"
                    : "apply_primary_button app_step3_apply_later_button_resp"
                }
              >
                {t("opportunity.apply_popup.apply_later")}
              </button>
              <button
                type="submit"
                className={
                  size.width > 768
                    ? "primary_button app_step3_next_button"
                    : "primary_button app_step3_next_button_resp"
                }
              >
                {size.width > 768 ? (
                  t("opportunity.apply_popup.next")
                ) : lang == "en" ? (
                  <AiOutlineArrowRight size="1.5rem" />
                ) : (
                  <AiOutlineArrowLeft size="1.5rem" />
                )}
              </button>
            </div>
          </div>
        </form>
      ) : (
        !previous && (
          <Apply_Step4
            setOpen={setOpen}
            open={open}
            data={oppData}
            openOutOfAreaa={openOutOfArea}
            opportunityId={opportunityId}
            setFooterWidth={setFooterWidth}
          />
        )
      )}
    </>
  );
}

export default Apply_Step3;
