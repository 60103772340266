import React, { useLayoutEffect, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import isAuth from "../Utils/isAuth";
import Header from "../components/Header/Header";
import Home from "../pages/Home/Home";
import Footer from "../components/Footer/Footer";
import MayorImpact from "./MayorOfImpact/MayorImpact";
import CompaniesInst from "./Company&Inst/Companies&Inst";
import VolunteerMang from "./VolunteerManagers/VolunteerMang";
import AboutUs from "./AboutUs/AboutUs";
import OurPartners from "./OurPartners/OurPartners";
import ContactUsPage from "./ContactUs/ContactUsPage";
import Faqs from "./Faqs/Faqs";
import Privacy from "./PrivacyAndPolicy/Privacy";
import Terms from "./Terms and conditions/Termsandconditions";
import ProfileStatus from "./Profile/ProfileStatus/ProfileStatus";
import ProfileInfo from "./Profile/ProfileInfo/ProfileInfo";
import Opportunities from "./Opportunities/Opportunities";
import MyOpportunities from "./Opportunities/Volunteers/MyOpportunities";
import Register from "./User/Register";
import EvalNCert from "./Profile/Evaluation&Cert/EvalNCert";
import CompleteProfile from "./CompleteProfile/CompleteProfile";
import Complete_Step1 from "./CompleteProfile/Volunteer/Complete-Step-1/Complete_Step1";
import Complete_Step2 from "./CompleteProfile/Volunteer/Complete-Step-2/Complete_Step2";
import Complete_Step3 from "./CompleteProfile/Volunteer/Complete-Step-3/Complete_Step3";
import Complete_Step4 from "./CompleteProfile/Volunteer/Complete-Step-4/Complete_Step4";
import Complete_Step5 from "./CompleteProfile/Volunteer/Complete-Step-5/Complete_Step5";
import Complete_Step6 from "./CompleteProfile/Volunteer/Complete-Step-6/Complete_Step6";
import Complete_Step7 from "./CompleteProfile/Volunteer/Complete-Step-7/Complete_Step7";
import Complete_Step8 from "./CompleteProfile/Volunteer/Complete-Step-8/Complete_Step8";
import Complete_Step9 from "./CompleteProfile/Volunteer/Complete-Step-9/Complete_Step9";
import Complete_Step10 from "./CompleteProfile/Volunteer/Complete-Step-10/Complete_Step10";
import CompleteOrg_Step1 from "./CompleteProfile/Organization/CompleteOrg-Step1/CompleteOrg_Step1";
import CompleteOrg_Step2 from "./CompleteProfile/Organization/CompleteOrg-Step2/CompleteOrg_Step2";
import CompleteOrg_Step3 from "./CompleteProfile/Organization/CompleteOrg-Step3/CompleteOrg_Step3";
import CompleteOrg_Step4 from "./CompleteProfile/Organization/CompleteOrg-Step4/CompleteOrg_Step4";
import CompleteOrg_Step5 from "./CompleteProfile/Organization/CompleteOrg-Step5/CompleteOrg_Step5";
import Opportunities_detail_card from "./Opportunities/Opportunities_detail_card/Opportunities_detail_card";
import NgoFeedbacks from "./Opportunities/NgoFeedbacks/NgoFeedbacks";
import OppRequests from "./Requests/OppRequests";
import ViewRequests from "./Requests/viewRequest/ViewRequests";
import CommunityAction from "./CommunityAction/Communityaction";
import MasterClass from "./MasterClass/MasterClass";
import Checkin_out from "./Checkin_Checkout/Checkin_out";
import Checkin_out_evaluation from "./Checkin_Checkout/Checkin_out_evaluation";
import Blogs from "./Blogs/Blogs";
import BlogDetail from "../components/BlogDetail/BlogDetail";
import VolunteerSpotLightDetail from "../components/VolunteerSpotLightDetail/VolunteerSpotLightDetail";
import NotFound from "./NotFound/NotFound";
import Donate from "./Donate/Donate.js";
import Quiz from "./Quiz/Quiz";
import Notifications from "./Notifications/Notifications";
import EvaluationFeedback from "./EvaluationFeedback/EvaluationFeedback";
import { useNavigate } from "react-router-dom";
import SubscriptionPages from "./SubscriptionPages/SubscriptionPages";
import AfterAuth from "./AfterAuth/AfterAuth";
import Completion from "../components/Stripe/Completion";
import DeleteAcount from "./DeleteAcount/DeleteAcount";
import Redirect from "./Redirect.js";
import RedirecOtpp from "./RedirecOtpp.js";

function PageSwitch() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const navigate = useNavigate();
  const [role, setrole] = useState("");
  const [islogedin, setislogedin] = useState(false);
  useEffect(() => {
    if (isAuth()) {
      setislogedin(true);
      setrole(localStorage.getItem("role"));
    }
  }, [location.pathname]);
  useEffect(() => {
    setrole(localStorage.getItem("role"));
  }, []);

  return (
    <>
      {/* Header component */}
      <Header />
      <Routes>
        {/* Home page */}
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/afterauth" element={<AfterAuth />} />
        <Route path="/completion" element={<Completion />} />
        <Route path="/mayors-of-impact" element={<MayorImpact />} />
        <Route
          path="/companies-and-institutions/:pageNum"
          element={<CompaniesInst />}
        />
        {islogedin && (
          <Route path="/profile-status" element={<ProfileStatus />} />
        )}

        {islogedin && <Route path="/profile-info" element={<ProfileInfo />} />}
        {islogedin && (
          <Route path="/evaluation-certificates" element={<EvalNCert />} />
        )}

        <Route path="/volunteer-managers" element={<VolunteerMang />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-partners" element={<OurPartners />} />

        <Route path="/termsandconditions" element={<Terms />} />
        <Route path="/listings" element={<RedirecOtpp />} />
        <Route path="/opportunities/:stepCnt" element={<Opportunities />} />
        <Route path="/blogs_media" element={<Blogs />} />
        <Route path="/blog/details/:id" element={<BlogDetail />} />
        <Route
          path="/volunteerSpotLight/details/:id"
          element={<VolunteerSpotLightDetail />}
        />
        <Route path="/completeProfile/step1" element={<Complete_Step1 />} />

        <>
          {" "}
          <Route path="/completeProfile/step2" element={<Complete_Step2 />} />
          <Route path="/completeProfile/step3" element={<Complete_Step3 />} />
          <Route path="/completeProfile/step4" element={<Complete_Step4 />} />
          <Route path="/completeProfile/step5" element={<Complete_Step5 />} />
          <Route path="/completeProfile/step6" element={<Complete_Step6 />} />
          <Route path="/completeProfile/step7" element={<Complete_Step7 />} />
          <Route path="/completeProfile/step8" element={<Complete_Step8 />} />
          <Route path="/completeProfile/step9" element={<Complete_Step9 />} />
          <Route path="/completeProfile/step10" element={<Complete_Step10 />} />
        </>

        <Route path="/oppDetail/:id" element={<Opportunities_detail_card />} />
        <Route path="/ngofeedbacks/:id" element={<NgoFeedbacks />} />
        {islogedin && role === "4" && (
          <Route path="/oppRequests" element={<OppRequests />} />
        )}
        <Route path="/viewRequests/:id" element={<ViewRequests />} />

        {((islogedin && role === "3") || !islogedin) && (
          <Route path="/communityAction" element={<CommunityAction />} />
        )}

        <Route path="/masterClass" element={<MasterClass />} />

        {islogedin && role === "4" && (
          <>
            <Route path="/checkin_out" element={<Checkin_out />} />
            <Route path="/subscription" element={<SubscriptionPages />} />
          </>
        )}
        <Route path="/donate" element={<Donate />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/notifications" element={<Notifications />} />

        {islogedin && role === "4" && (
          <>
            <Route
              path="/completeProfile/org/step1"
              element={<CompleteOrg_Step1 />}
            />
            <Route
              path="/completeProfile/org/step2"
              element={<CompleteOrg_Step2 />}
            />
            <Route
              path="/completeProfile/org/step3"
              element={<CompleteOrg_Step3 />}
            />
            <Route
              path="/completeProfile/org/step4"
              element={<CompleteOrg_Step4 />}
            />
            <Route
              path="/completeProfile/org/step5"
              element={<CompleteOrg_Step5 />}
            />
          </>
        )}
        <Route path="/registervol" element={<Redirect />} />
        <Route exact path="/auth/:page" element={<Register />} />
        <Route exact path="/contactUsPage" element={<ContactUsPage />} />
        <Route exact path="/faqs" element={<Faqs />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/deleteaccount" element={<DeleteAcount />} />

        <Route exact path="/*" element={<NotFound />} />
        <Route
          path="/checkin_out_evaluation/:id"
          element={<Checkin_out_evaluation />}
        />
        <Route
          path="/evaluation_feedback/:id"
          element={<EvaluationFeedback />}
        />
      </Routes>{" "}
      <Footer />
    </>
  );
}

export default PageSwitch;
