import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function RedirecOtpp() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/opportunities/1", { replace: true });
  }, [navigate]);

  return null; // No need to render anything
}

export default RedirecOtpp;
